import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "./views/Index.js";
import Login from "./views/examples/Login";
import Register from "./views/examples/Register";
import Profile from "./views/examples/Profile";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/track" exact render={(props) => <Profile {...props} />} />
      <Route
        path="/register"
        exact
        render={(props) => <Register {...props} />}
      />
      <Redirect to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
