import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";
import UserManager from "./UserManager";
const baseURL = "https://golf-backend-btb.herokuapp.com";
// const baseURL = "http://localhost:8080";

const headers = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${UserManager.getToken() || ""}`,
});

const baseConfig = (): AxiosRequestConfig => ({
  baseURL,
  headers: headers(),
  validateStatus: (status) => status < 550,
});

export const handleResponse = (
  response: AxiosResponse,
  handleUnauthed: boolean
) => {
  if (response.status >= 300) {
    if (response.status === 401 && handleUnauthed) {
      UserManager.clear();
      window.location.href = "/login";
    }

    if (response.data) {
      throw response.data.reason;
    } else {
      throw new Error("Something went wrong.");
    }
  } else {
    return response.data || response.status;
  }
};

export const handleGetRequest = async (
  endpoint: string,
  params?: object,
  handleUnauthed: boolean = true
) => {
  let path = endpoint;
  if (params) {
    const paramsString = queryString.stringify(params);
    path = `${endpoint}?${paramsString}`;
  }
  const response = await axios.get(path, baseConfig());
  return handleResponse(response, handleUnauthed);
};

export const handlePostRequest = async (
  endpoint: string,
  data: object = {},
  handleUnauthed: boolean = true
) => {
  const response = await axios.post(endpoint, data, baseConfig());
  return handleResponse(response, handleUnauthed);
};

export const handlePatchRequest = async (
  endpoint: string,
  update: object = {},
  handleUnauthed: boolean = true
) => {
  const response = await axios.patch(endpoint, update, baseConfig());
  return handleResponse(response, handleUnauthed);
};

export const handleDeleteRequest = async (
  endpoint: string,
  handleUnauthed: boolean = true
) => {
  const response = await axios.delete(endpoint, baseConfig());
  return handleResponse(response, handleUnauthed);
};
