import React, { RefObject } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import API from "../../helpers/API/api";
import UserManager from "../../helpers/API/UserManager";

interface State {
  isDone: boolean;
  buttonDisabled: boolean;
}

class Login extends React.Component<{}, State> {
  private emailRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isDone: false,
      buttonDisabled: false,
    };

    this.emailRef = React.createRef();
  }

  componentDidMount() {
    if (UserManager.isLoggedIn()) {
      window.location.href = "/track";
    }
  }

  submitEmail = async () => {
    this.setState({ buttonDisabled: true });
    try {
      const token = await API.exchangeLoginToken(this.emailRef.current!.value);
      UserManager.saveToken(token);
      const user = await API.getProfile();
      UserManager.saveProfile(user);
      window.location.href = "/track";
    } catch (e) {
      window.alert("Something went wrong - check your username.");
    }
  };

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      {this.state.isDone ? (
                        <p>
                          Thanks! If there's an account with that email address
                          we'll send you a link to sign in.
                        </p>
                      ) : (
                        <>
                          <div className="text-center text-muted mb-4">
                            <small>Enter your username to login</small>
                          </div>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              this.submitEmail();
                            }}
                            role="form"
                          >
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  required
                                  innerRef={this.emailRef}
                                  placeholder="Username"
                                  type="text"
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                disabled={this.state.buttonDisabled}
                                onClick={this.submitEmail}
                                className="my-4"
                                color="primary"
                                type="button"
                              >
                                Login
                              </Button>
                            </div>
                            <div className="text-center">
                              <a href="/register">Register</a>
                            </div>
                          </Form>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Login;
