/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
  FormGroup,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import UserManager from "../../helpers/API/UserManager";
import { Results } from "../../helpers/API/users.js";
import API from "../../helpers/API/api";
import { defaultHoleData } from "../../helpers/data";

interface Props {}

interface State {
  results: Results;
  course: number;
}

class Profile extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>;

  private frontNineRef: React.RefObject<HTMLInputElement>;
  private backNineRef: React.RefObject<HTMLInputElement>;
  private courseParRef: React.RefObject<HTMLInputElement>;
  private frontNineLongRef: React.RefObject<HTMLInputElement>;
  private backNineLongRef: React.RefObject<HTMLInputElement>;
  private courseParLongRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      results: {
        results: {
          id: 0,
          itemNumber: 0,
          firstChallengeResults: 0,
          secondChallengeResults: 0,
          thirdChallengeResults: 0,
          fourthChallengeResults: 0,
          fifthChallengeResults: 0,
        },
      },
      course: 0,
    };

    this.inputRef = React.createRef();
    this.frontNineRef = React.createRef();
    this.backNineRef = React.createRef();
    this.courseParRef = React.createRef();
    this.frontNineLongRef = React.createRef();
    this.backNineLongRef = React.createRef();
    this.courseParLongRef = React.createRef();
  }

  async componentDidMount() {
    if (!UserManager.isLoggedIn()) {
      window.location.href = "/login";
    }

    // @ts-ignore
    document.documentElement.scrollTop = 0;
    // @ts-ignore
    document.scrollingElement.scrollTop = 0;
    // @ts-ignore
    this.refs.main.scrollTop = 0;

    await this.getData();
  }

  getData = async () => {
    const results = await API.getCourse(this.state.course);
    this.setState({
      results,
    });
  };

  updateValue = async (hole: number) => {
    await API.updateCourse(
      this.state.course,
      hole,
      Number(this.inputRef.current!.value)
    );
    this.updateItem("increase");
  };

  updateItem = async (type: "increase" | "decrease") => {
    await API.updateCourseItemNumber(this.state.course, type);
    await this.getData();
  };

  selectChallenge = async (challenge: number) => {
    await API.updateChallenge(
      this.state.course,
      challenge,
      Math.floor(Math.random() * 3) === 0 ? 2 : 1
    );
    await this.getData();
  };

  skip = async () => {
    await API.skip(
      this.state.course,
      Number(this.frontNineRef.current!.value),
      Number(this.backNineRef.current!.value),
      Number(this.courseParRef.current!.value)
    );
    await this.getData();
  };

  submitFrontNine = async () => {
    await API.addFrontNine(
      this.state.course,
      Number(this.frontNineLongRef.current!.value)
    );
    await this.getData();
  };

  submitBackNine = async () => {
    await API.addBackNine(
      this.state.course,
      Number(this.backNineLongRef.current!.value)
    );
    await this.getData();
  };

  submitCoursePar = async () => {
    await API.addCoursePar(
      this.state.course,
      Number(this.courseParLongRef.current!.value)
    );
    await this.getData();
  };

  render() {
    const challengeResults =
      defaultHoleData[this.state.results.results.itemNumber].challengeNumber ===
      1
        ? this.state.results.results.firstChallengeResults
        : defaultHoleData[this.state.results.results.itemNumber]
            .challengeNumber === 2
        ? this.state.results.results.secondChallengeResults
        : defaultHoleData[this.state.results.results.itemNumber]
            .challengeNumber === 3
        ? this.state.results.results.thirdChallengeResults
        : defaultHoleData[this.state.results.results.itemNumber]
            .challengeNumber === 4
        ? this.state.results.results.fourthChallengeResults
        : defaultHoleData[this.state.results.results.itemNumber]
            .challengeNumber === 5
        ? this.state.results.results.fifthChallengeResults
        : 0;

    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col
                      className="order-lg-3 text-lg-center align-self-lg-center"
                      lg="12"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-2"
                          color="warning"
                          href="#pablo"
                          disabled={this.state.results.results.itemNumber === 0}
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.results.results.itemNumber > 0) {
                              this.updateItem("decrease");
                            }
                          }}
                          size="sm"
                        >
                          Previous
                        </Button>
                        <Button
                          className="mr-2"
                          color="info"
                          href="#pablo"
                          disabled={
                            this.state.results.results.itemNumber ===
                            defaultHoleData.length - 1
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              this.state.results.results.itemNumber <
                              defaultHoleData.length - 1
                            ) {
                              this.updateItem("increase");
                            }
                          }}
                          size="sm"
                        >
                          Next
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    {defaultHoleData[this.state.results.results.itemNumber]
                      .multipleLogos ? (
                      <Row>
                        <Col lg={{ size: 6, offset: 3 }}>
                          <UncontrolledCarousel
                            items={
                              defaultHoleData[
                                this.state.results.results.itemNumber
                              ].multipleLogos?.map((l) => {
                                return {
                                  src: l,
                                  altText: "",
                                  caption: "",
                                };
                              }) ?? []
                            }
                          />
                        </Col>
                      </Row>
                    ) : (
                      <img
                        alt="Logo"
                        width="30%"
                        src={
                          defaultHoleData[this.state.results.results.itemNumber]
                            .logoItem ?? require("../../assets/logos/blank.jpg")
                        }
                      />
                    )}
                    <h6>
                      <strong>Beat Tom and Bill!</strong>
                    </h6>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                        <h5>
                          {
                            defaultHoleData[
                              this.state.results.results.itemNumber
                            ].name
                          }
                        </h5>
                      </Col>
                    </Row>
                    <>
                      <Row>
                        <Col lg={12}>
                          <p>
                            {
                              defaultHoleData[
                                this.state.results.results.itemNumber
                              ].content
                            }
                          </p>
                        </Col>
                      </Row>
                      {defaultHoleData[this.state.results.results.itemNumber]
                        .isRules ? (
                        <>
                          <p>
                            <strong>
                              You have two choices... either enter your front
                              nine, back nine, and course par values now, or
                              play through the simulation to get the full BTB
                              experience. Enter your values below if you want to
                              skip to the end:
                            </strong>
                          </p>
                          <Form role="form">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.frontNineRef}
                                  defaultValue={
                                    this.state.results.results.frontNine
                                  }
                                  placeholder="Front Nine Score"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.backNineRef}
                                  defaultValue={
                                    this.state.results.results.backNine
                                  }
                                  placeholder="Back Nine Score"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.courseParRef}
                                  defaultValue={
                                    this.state.results.results.coursePar
                                  }
                                  placeholder="Course Par"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                onClick={this.skip}
                                className="my-4"
                                color="primary"
                                type="button"
                              >
                                Skip Simulation
                              </Button>
                            </div>
                          </Form>
                        </>
                      ) : undefined}

                      {defaultHoleData[this.state.results.results.itemNumber]
                        .isFrontNine ? (
                        <>
                          <Form role="form">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-front-9-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.frontNineLongRef}
                                  defaultValue={
                                    this.state.results.results.frontNine
                                  }
                                  placeholder="Front Nine Score"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                onClick={this.submitFrontNine}
                                className="my-4"
                                color="primary"
                                type="button"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </>
                      ) : undefined}

                      {defaultHoleData[this.state.results.results.itemNumber]
                        .isBackNine ? (
                        <>
                          <Form role="form">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-back-9-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.backNineLongRef}
                                  defaultValue={
                                    this.state.results.results.backNine
                                  }
                                  placeholder="Back Nine Score"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                onClick={this.submitBackNine}
                                className="my-4"
                                color="primary"
                                type="button"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </>
                      ) : undefined}

                      {defaultHoleData[this.state.results.results.itemNumber]
                        .isCoursePar ? (
                        <>
                          <Form role="form">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <Input
                                  key={`number-course-par-input-${this.state.results.results.itemNumber}-${this.state.course}`}
                                  innerRef={this.courseParLongRef}
                                  defaultValue={
                                    this.state.results.results.coursePar
                                  }
                                  placeholder="Course Par"
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                              <Button
                                onClick={this.submitCoursePar}
                                className="my-4"
                                color="primary"
                                type="button"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </>
                      ) : undefined}

                      {defaultHoleData[this.state.results.results.itemNumber]
                        .isSummary ? (
                        <>
                          <p>
                            <strong>
                              Bill and Tom were -2 for the course... you were{" "}
                              {(this.state.results.results.frontNine ?? 0) +
                                (this.state.results.results.backNine ?? 0) -
                                (this.state.results.results.coursePar ?? 0) >
                              0
                                ? "+"
                                : ""}
                              {(this.state.results.results.frontNine ?? 0) +
                                (this.state.results.results.backNine ?? 0) -
                                (this.state.results.results.coursePar ?? 0)}
                              !
                            </strong>
                          </p>
                        </>
                      ) : undefined}

                      {defaultHoleData[this.state.results.results.itemNumber]
                        .challengeNumber !== undefined ? (
                        <>
                          {challengeResults === 0 ? (
                            <>
                              <strong>
                                Pick one of the pictures to see if you're a
                                winner!
                              </strong>
                              <Row className="text-center">
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt1.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt2.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt3.jpg")}
                                    />
                                  </a>
                                </Col>
                              </Row>
                              <Row className="text-center">
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt4.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt5.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt6.jpg")}
                                    />
                                  </a>
                                </Col>
                              </Row>
                              <Row className="text-center">
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt7.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt8.jpg")}
                                    />
                                  </a>
                                </Col>
                                <Col lg={{ size: 1, offset: 2 }}>
                                  <a
                                    href="https://google.com"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.selectChallenge(
                                        defaultHoleData[
                                          this.state.results.results.itemNumber
                                        ].challengeNumber!
                                      );
                                    }}
                                  >
                                    <img
                                      alt="Challenge"
                                      src={require("../../assets/imgs/challenge-taunt9.jpg")}
                                    />
                                  </a>
                                </Col>
                              </Row>
                            </>
                          ) : challengeResults === 1 ? (
                            <span style={{ color: "red" }}>
                              <strong>
                                Sorry, my friend, even virtual, you LOSE to Tom
                                & Bill! 
                              </strong>
                            </span>
                          ) : (
                            <span style={{ color: "green" }}>
                              <strong>
                                Congrats! You've won the challenge.
                              </strong>
                            </span>
                          )}
                        </>
                      ) : undefined}
                      {defaultHoleData[this.state.results.results.itemNumber]
                        .holeNumber !== undefined ? (
                        <Row className="mt-4 justify-content-center">
                          <Button
                            onClick={() => this.updateItem("increase")}
                            className="my-4"
                            color="primary"
                            type="button"
                          >
                            Find out if you beat Tom and Bill!
                          </Button>
                        </Row>
                      ) : (
                        <div className="card-profile-actions py-4 mt-lg-0">
                          <Button
                            className="mr-2"
                            color="warning"
                            href="#pablo"
                            disabled={
                              this.state.results.results.itemNumber === 0
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.results.results.itemNumber > 0) {
                                this.updateItem("decrease");
                              }
                            }}
                            size="sm"
                          >
                            Previous
                          </Button>
                          <Button
                            className="mr-2"
                            color="info"
                            href="#pablo"
                            disabled={
                              this.state.results.results.itemNumber ===
                              defaultHoleData.length - 1
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                this.state.results.results.itemNumber <
                                defaultHoleData.length - 1
                              ) {
                                this.updateItem("increase");
                              }
                            }}
                            size="sm"
                          >
                            Next
                          </Button>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Profile;
