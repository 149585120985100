import React from "react";
import { UncontrolledCarousel, Row, Col } from "reactstrap";

export interface HoleData {
  name: string;
  holeNumber?: number;
  content: JSX.Element;
  logoItem?: string;
  multipleLogos?: Array<string>;
  challengeNumber?: number;
  isRules?: boolean;
  isSummary?: boolean;
  isFrontNine?: boolean;
  isBackNine?: boolean;
  isCoursePar?: boolean;
}

export const defaultHoleData: Array<HoleData> = [
  {
    name: "Introduction by Tom and Bill",
    holeNumber: undefined,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/intro-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/intro-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/intro-3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <p>
              Hello Friends! Since 2002, our golf event – Beat Tom and Bill,
              Beat Cancer – was like a good mail carrier who trudged along
              despite rain, sleet, and in one memorable year – Snow. This year’s
              obstacle, The Coronavirus, proved to be too strong a foe, forcing
              us to cancel our annual event after we had made the commitment to
              support 12 individual families through our golf outing and 5 more
              through our Scholarship program – ALL RECORD NUMBERS!{" "}
            </p>
            <p>
              Still, we wanted to try and raise some money for those needy
              families whom you’ll meet throughout this event. Exactly why
              you’re here!
            </p>
            <p>
              So, first off – THANK YOU for agreeing to be part of our 1st (and
              hopefully ONLY)…
            </p>
            <h1>VIRTUAL BEAT TOM AND BILL</h1>
            <p>
              Even though we won’t be doing this in person, because of the
              on-line platform, you’ll still have your chance to achieve one of
              the hardest feats in sport today – BEATING TOM AND BILL – and
              attaining the greatest of all awards in sports – The BEAT TOM AND
              BILL BOBBLEHEAD!{" "}
            </p>
            <p>
              So, whether you’ve golfed a round at your regular country club, a
              local municipal course or the neighborhood Putt-Putt, we’re going
              to make it feel like you’re at our annual event, attempting the
              impossible while also helping some local families fighting cancer.{" "}
            </p>
            <p> We’re glad you’ve joined us. Now… on to the carnage!</p>
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/lHvk54O_d-E"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/fqFNxNQiw3Q"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro 2"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/XRIrYTAKGOc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro 2"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6Phr55ZF-M8"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Rules",
    holeNumber: undefined,
    isRules: true,
    content: (
      <>
        <p>In person, the BTB is a 2-person scramble.</p>
        <p>
          2 people would join 300 other intrepid souls, all coming together to
          do their best to beat the brothers Latourette.
        </p>
        <p>
          We’re equal opportunity. 2 men. A man and woman. 2 women. However you
          identify, whatever your pronoun. ALL are welcome at Beat Tom and Bill.
          We’re an equal opportunity dream destroyer.
        </p>
        <p>For THIS, our virtual outing:</p>
        <ul>
          <li>
            You can play against us by yourself or with 1 friend. Maybe you’d
            like to have 2 friends with you? Sure. Maybe a clown car full of
            golfers? YES! Bring us your Circus!
          </li>
          <li>
            You can play on a regular golf course, a miniature golf course, WII
            golf. We don’t care!!!{" "}
          </li>
        </ul>
        <p>The only rule – NO CHEATING!!! </p>
        <p>
          For 19 years, Tom and Bill have NEVER, EVER improved their lie, kicked
          the ball from the rough to the fairway, re-putted 3 times until they
          sank one, taken more than 4 well-earned Mulligans off the tee…
        </p>
        <p>So you shouldn’t cheat either!</p>
        <p>You shot your round, just tell us what your score was…</p>
        <p>
          And REMEMBER – You’ll have to face whatever God you believe in at some
          point. Do you really want this on the tally sheet?
        </p>
        <p>What are we playing for, you ask?</p>
        <p>
          Just like in our regular outing – every time you best us, you’ll get
          extra chances for the world famous…
        </p>
        <p>
          WHEEL OF MEAT!!! Live Facebook spins on Friday 10/16 – 10/23 – 10/30 –
          11/6.
        </p>
        <ul>
          <li>You get an entry for playing!!!</li>
          <li>The more times you beat us, the more chances you’ll have!</li>
          <li>The more times you play us, the more chances you’ll get!</li>
        </ul>
        <p>2 Choices here… </p>
      </>
    ),
  },
  {
    name: "National Anthem",
    holeNumber: undefined,
    content: (
      <>
        <p>
          Over 10 years ago, our good friend and music producer Joe Dilillo
          started playing the Electric National Anthem to kick off our event.
          Then he took a young Joey Noelle under his wing, when Joey’s guitar
          was almost bigger then he was. Now, he’s a fine young man who’s
          incredibly talented and just recently dropped a new CD!
        </p>
        <p>
          Check it out: Jo Noel – Only Sometimes. Available wherever you stream
          or download music!
        </p>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5j7M_khTpsI"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/anthem-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/anthem-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/anthem-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/anthem-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/anthem-6.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "God Bless America",
    holeNumber: undefined,
    content: (
      <>
        <p className="mt-3">
          Claire Latourette, Tom’s oldest daughter, has joined us for the last
          few years to bring her beautiful voice to the course. She’s a
          professional actor and has been on numerous Chicagoland stages.{" "}
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/anthem-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/god-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/god-2.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9jGPMcw5EbI"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p>
          Check her out at{" "}
          <a href="http://www.clairelatourette.com">
            http://www.clairelatourette.com/
          </a>
        </p>
      </>
    ),
  },
  {
    name: "Shotgun Start",
    holeNumber: undefined,
    content: (
      <>
        <p>
          OK – let’s get this party started. The sooner you begin, the sooner
          you can finish. And remember, there’s no shame in giving up early. If
          by hole # 3, you’re ready to wave the white flag and call it quits,
          we’re good with that. No refunds, of course, but we will give you a
          pat on your backside and an encouraging but somewhat condescending
          “There’s always next year” perfunctory acknowledgment.
        </p>
        <p>As the great almighty AC/DC said:</p>
        <p>
          “Stand up and be counted
          <br />
          For what you are about to receive
          <br />
          We are the dealers <br />
          We’ll give you everything you need <br />
          Hail, hail to the good times <br />
          Tom & Bill have got the right of way
          <br />
          They ain’t no legend, their mighty cause <br />
          They’re just bringing it for today
          <br />
          For those about to suck, WE SALUTE YOU! <br />
          Pick up your balls and load up your cannon
          <br />
          For a 21 gun salute
          <br />
          FOR THOSE ABOUT TO SUCK… FIRE!!!" <br />
        </p>
        <img
          alt="Logo"
          width="60%"
          src={require("../assets/imgs/shotgun-1.jpg")}
        />
        <iframe
          className="mt-3"
          title="shotgun"
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FBeatTomandBill%2Fvideos%2F354566238746700%2F&show_text=0&width=476"
          width="476"
          height="476"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowTransparency
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ao1LoGjXdPM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/oW9gUy7OuUU"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "The Beat Tom and Bill Zone",
    holeNumber: undefined,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/shotgun1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotgun2.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 1: Playing for the Bobblehead",
    holeNumber: 1,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole1-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-13.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-14.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole1-15.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/HhZvJrB1usE"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p className="mt-2">
          Yes, my friends! It’s what you’re playing for today.
        </p>
        <p>
          Better than the Claret Jug, the Wanamaker Trophy, or the Stanley Cup,
          the Beat Tom and Bill Trophy is sought after by many but has been
          claimed by a select, very lucky few.
        </p>
        <p>
          Perhaps you’ll be able to pull a horseshoe out of your backside and
          string enough magic from your rabbit’s foot to accomplish the hardest
          thing in sports but we doubt it. You’ll surely find out like all the
          others…
        </p>
        <strong>
          <i>You Can't Beat Tom and Bill!</i>
        </strong>
      </>
    ),
  },
  {
    name: "BTB Hole 1 Taunt",
    holeNumber: undefined,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-1.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt1-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "BTB Hole 2: The Haunted House Hole",
    holeNumber: 2,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole2-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-13.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-14.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-15.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole2-16.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Your nightmare begins! As you tee off, a horde of hungry, ravenous
          zombies look to devour, digest and dine on your decrepit drive. In the
          fairway, our Killer Clowns will tempt you to enter the woods…
        </p>
        <strong>
          <i>GEORGIE! We all Float Down here!!!</i>
        </strong>
      </>
    ),
  },
  {
    name: "BTB Hole 2 Taunt",
    holeNumber: undefined,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt2-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt2-3.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – The Polka Express ",
    holeNumber: undefined,
    logoItem: require(`../assets/logos/batman.jpg`),
    content: (
      <>
        <p>Eddie Korosa Jr is a POLKA STAR!</p>
        <p>
          He’s been bringing his Polka Express to our golf outing for 10+ years.{" "}
        </p>
        <p>
          This guy knows a thing or two about Polka. Starting at the age of 7,
          he played drums for his dad, America’s Prince of Polkas, and was
          behind the kit when Pope John Paul II stopped at the Baby Doll Polka
          Club in 1979. Since 1976, his band - THE BOYS FROM ILLINOIS have been
          entertaining thousands of Polka fans throughout the world!
        </p>
        <p>
          Perhaps you saw him in the movie Home Alone, when Eddie played the
          part of John Candy’s band “The Kenosha Kickers”!
        </p>
        <p>
          Eddie is a Polka America “Frankie” award winner and worthy recipient
          of the Chicago Music Awards “Lifetime Achievement” and “Best Polka
          Entertainer”
        </p>
        <p>
          Plus, he’s a GREAT, GREAT guy and can be reached at EddieKorosaJr.com
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction1-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction1-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction1-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction1-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction1-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction1-6.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/lIMuzIWkdBA"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3MggLqMKH0E"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fabileneparadoxquartet%2Fvideos%2F1944051058946755%2F&show_text=0&width=267"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fabileneparadoxquartet%2Fvideos%2F1944058122279382%2F&show_text=0&width=267"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Cq7fO_VWnHw"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Meet The Families - This Year 1",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 19 years, we’ve given over $1.3M to over 100 families. This year,
          we committed to help 12 families and 5 Scholarship winners. Here’s
          your first chance to meet a few of these tremendous families.
        </p>
        <Row>
          <Col lg={12}>
            <img
              alt="Family"
              width="60%"
              src={require("../assets/imgs/families1.jpg")}
            />
          </Col>
        </Row>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/bryans-page.html"
          >
            Bryan Mosak
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/tricias-page.html"
          >
            Tricia Tinsley
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/roberts-page.html"
          >
            Robert Roon
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/lauras-page.html"
          >
            Laura Howarth
          </a>
        </p>
        <p>
          In 19 years, we’ve given over $1.3M to over 100 families. This year,
          we committed to help 12 families and 5 Scholarship winners. Watch the
          video (featuring singing by Tom’s daughters Claire & Christine) to see
          all the families we’re supporting and then click on their names to
          learn more.    This is your first chance to meet a few of the
          recipients of your generosity!      
        </p>
        <iframe
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FBeatTomandBill%2Fvideos%2F764579967413931%2F&show_text=0&width=560"
          width="560"
          height="315"
          title="Facebook"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowTransparency
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "BTB Hole 3: The Referees Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 3,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole3-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole3-7.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          The Refs are getting ready to throw some flags for your flagrant
          flailing which you call a golf game!
        </p>
        <p>
          <strong>
            <i>Encroachment! 5 Yards and loss of down!</i>
          </strong>
        </p>
        <p>
          <strong>
            <i>Interference for impersonating a golfer!</i>
          </strong>
        </p>
        <p>
          <strong>
            <i>
              Offensive Holding! – You there, get your hands out of your pants
              and put you ball on the tee!!!
            </i>
          </strong>
        </p>
        <p>
          <strong>
            <i>10 yard penalty at the spot of the foul!</i>
          </strong>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 3 Taunt",
    holeNumber: undefined,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-3-1.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt-3-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt3-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "BTB Hole 4: The T-Rex Hole",
    holeNumber: 4,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole4-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole4-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole4-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole4-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole4-5.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          The Tyrannosaurus rex was the “KING” of the Dinosaurs, a carnivore
          with short but unusually powerful forelimbs and the speed of an
          ostrich.
        </p>
        <p>Remind you of anyone?</p>
        <p>
          On this course, all you herbivores are being humbled and pursued by
          the predators known as Tom & Bill!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 4 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-4.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt4-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – The Sisters of The Church of Latourette Day Saints",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          Our outing starting in 2002 to honor and support Paula Guzzetta, the
          wife of our childhood friend Paul. Paula was undergoing a battle with
          breast cancer that she won so, with her wacky sense of humor, she
          decided to design the perfect golfing distraction.{" "}
        </p>
        <p>
          Her and her good friend Lisa Sorensen spend the day in Nun’s frocks,
          serving shots of “Hot Sex”, rapping the knuckles of naughty golfers,
          and making many of us remember nunnery nightmares of grammar school!
        </p>
        <p>
          One year, our Nuns were unable to make the event so their stand-ins –
          Connie Allen & Maureen Barnett – took the debauchery to another level
          by getting on their knees and blessing golfer’s balls before they
          unleashed their drives! Needless to say, there were many shanked and
          errant shots on the course that day!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction2-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction2-10.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Meet the Families - This Year 2",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 19 years, we’ve given over $1.3M to over 100 families. This year,
          we committed to help 12 families and 5 Scholarship winners. Here’s
          another chance to meet a few of these tremendous families.
        </p>
        <Row>
          <Col lg={12}>
            <img
              alt="Family"
              width="60%"
              src={require("../assets/imgs/families2.jpg")}
            />
          </Col>
        </Row>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/eds-page.html"
          >
            Edward Schrank
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/austins-page.html"
          >
            Austin Miller
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/treys-page.html"
          >
            Trey Gelsomino
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/joes-page.html"
          >
            Joe Morgan
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/maryanns-page.html"
          >
            Maryann Bawoll
          </a>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 5: The Long Driver Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 5,
    content: (
      <>
        <p>
          Historically, this is the hole where Tom and Bill would actually throw
          you a bone and give you a helping hand by hiring award-winning Long
          Drivers to supplement your puny drives! They’d hit it 350+ yards so
          you could replace your 125 yard shank or 83 yard dribbler and have a
          prayer of besting Tom and Bill!
        </p>
        <p>We’re now virtual but Tom and Bill are still in a giving mood so…</p>
        <p>SUBTRACT A SHOT FROM YOUR SCORE ON THIS HOLE, MY FRIENDS!</p>
        <p>You’re going to need all the help you can get!!!</p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole5-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole5-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole5-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole5-4.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 5 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-5.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt5-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Long Drive Challenge",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    challengeNumber: 1,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/longdrive.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          At our outing, there are many opportunities to best Tom and Bill. Even
          though most mortals can only dream of achieving this lofty feat, we
          believe it’s important to allow people that chance.
        </p>
        <p>
          Since we can’t do this in person this year, we’re going to do a
          Visualization exercise. This will be your moment of Zen or meditation
          for the day…
        </p>
        <p>Imagine you’re on the tee box, looking down the fairway.</p>
        <p>Squint your eyes and keep looking. Train your eyes far, far away.</p>
        <p>Hundreds of yards away from where you stand.</p>
        <p>
          There’s a sign in the middle of the fairway. It’s very small because
          it’s so far away.
        </p>
        <p>Teeny, tiny…</p>
        <p>
          That, my friends, is where Tom and Bill’s ball landed after their
          finely tuned muscles launched another explosive drive.
        </p>
        <p>
          It’s very, very, very rare that you’d even have a chance of executing
          the GREATEST SHOT OF YOUR LIFETIME to beat these 2 perennial winners
          but this year, the year of Covid, gives you a unique opportunity!
          While it’s a 1 in a Million chance on the live course, this year
          you’re odds are much, much better!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 6: The Miniature Golfer Hole",
    holeNumber: 6,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole6-1.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Our good friend, Chris Errera, is an extremely talented musician BUT
          not so good a golfer… Still, he’d grace our golf hole with his
          melodious playing. Short of stature with a HUGE heart, winners of the
          challenges will be getting a copy of Chris’s fantastic CD – Enter the
          Twilight.
        </p>
        <p>Check him out:</p>
        <iframe
          title="Hole 6"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/WJp1467rSq0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          title="Hole 6"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/VTR7-ArlKzQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p>And as Chris would tell the golfers who graced his hole...</p>
        <strong>
          <i>DON'T HIT IT SHORT!!!</i>
        </strong>
      </>
    ),
  },
  {
    name: "BTB Hole 6 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-6.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt6-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – The Shot Cops ",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 2011, we helped the family of Lisa Serio as she was undergoing her
          cancer battle. Lisa fought like hell and beat her disease and decided
          to come back annually to provide another favorite distraction – The
          Shot Cops.
        </p>
        <p>
          Lisa, and her co-workers - NICU nurses at Advocate / Lutheran General
          – annually dress up as extremely attractive Police Officers, offering
          libations for the thirsty golfers and writing tickets for:
        </p>
        <ul>
          <li>
            Improper lane usage - when an errant shot lands in another fairway
          </li>
          <li>
            DUI – Driving with Impotency - when your first shot doesn’t get past
            the Ladie’s tees
          </li>
          <li>Reckless Driving – They write hundreds of this one!</li>
        </ul>
        <p>
          The most egregious, multiple offenders might even find themselves
          handcuffed to their cart as The Shot Cops water board them with
          whiskey or give a gentle spanking for being bad, bad golfers!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction3-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/shotcop13.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Meet the Families - This Year 3",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 19 years, we’ve given over $1.3M to over 100 families. This year,
          we committed to help 12 families and 5 Scholarship winners. Here’s
          another chance to meet a few of these tremendous families.
        </p>
        <Row>
          <Col lg={12}>
            <img
              alt="Family"
              width="60%"
              src={require("../assets/imgs/families3.jpg")}
            />
          </Col>
        </Row>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/kens-page.html"
          >
            Ken Wiese
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/nadezhdas-page.html"
          >
            Nadezhda Todorov
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/megs-page.html"
          >
            Meg Keating
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.btbfoundation.org/patricias-page.html"
          >
            Patricia Shanahan
          </a>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 7: The Nerf Gun Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 7,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole7-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole7-2.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Rated our most annoying hole EVER, this is where we’d let the children
          of our volunteers hang out with nerf guns. How could we predict that
          without any adult supervision they’d resort to LORD OF THE FLIES-like
          debauchery and depravity, giving our golfers mere seconds to hit their
          drives before shooting their balls off the tee?
        </p>
        <strong>
          <i>Don’t blame us - Blame their parents!</i>
        </strong>
        <p>Where is our society heading?</p>
      </>
    ),
  },
  {
    name: "BTB Hole 7 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-7.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt7-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "BTB Hole 8: The Lump a Lawyer Hole",
    holeNumber: 8,
    logoItem: require(`../assets/logos/main.jpg`),
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole8-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole8-2.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <strong>
          <i>What’s the difference between a good lawyer and a bad lawyer?</i>
        </strong>
        <p>
          A bad lawyer might let a case drag on for several years. A good lawyer
          knows how to make it last even longer.
        </p>
        <p>
          Even though our lifelong friend Mike Krzak is one of the good guys,
          this hole is your chance to take your frustrations out on the law
          profession. If you can hit the personal injury lawyer with your errant
          drive, you win!{" "}
        </p>
        <p>
          <a href="https://www.krlawgroup.com/">https://www.krlawgroup.com/</a>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 8 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-8.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt8-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt8-3.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt8-4.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt8-5.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – The Belly Dancers",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          “Mahira is a highly skilled master of muscular control” and has
          brought her amazing dancing and performing skills to the golf course.
          We apologize if her practice sessions were a bit of a distraction.
        </p>
        <p>
          Known as “Chicago’s best ziller”, we’ve heard that her acappella zill
          solos may have caused you a case of “focal dystonia”, better known as
          “The Yips!”
        </p>
        <p>
          Perhaps the hypnotizing undulation of her hips caused you to yank your
          hosel rocket.
        </p>
        <p>
          Maybe her fast and precise finger cymbals caused you to chili-dip and
          chunk your chip.
        </p>
        <p>
          If that’s the case, Tom and Bill apologize from the bottom of their
          hearts. Please believe us when we tell you we’ll do our best to insure
          this NEVER happens again!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction4-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction4-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction4-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction4-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction4-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/belly1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/belly2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/belly3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/belly4.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7trACjPboB0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vDbkQr2Qeyw"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Meet the Scholarship Winners",
    logoItem: require(`../assets/logos/pappy.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          The Vincent J. Vittore Memorial Scholarship was founded by the BTB
          Foundation to honor the enthusiasm, humor and strength that our good
          friend Vince displayed during his life and for his love for family,
          friends and great journalism.
        </p>
        <p>
          The primary way we raise funds for this scholarship is through
          Pappy-Fest, where a small group of committed Whiskey drinkers gather
          annually to taste a few bottles of specially aged Whiskey, with a few
          bottles of limited availability Pappy Van Winkle usually among the
          entrants.
        </p>
        <p>
          To learn more about the eligibility requirements and this year’s
          honorees, click here --{" "}
          <a href="http://www.btbfoundation.org/vincent-j-vittore-memorial-scholarship.html">
            http://www.btbfoundation.org/vincent-j-vittore-memorial-scholarship.html
          </a>
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/scholarship-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/scholarship-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/scholarship-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/scholarship-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/scholarship-5.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Hole 9: The Milk a Cow Hole",
    logoItem: require(`../assets/logos/cow.jpg`),
    holeNumber: 9,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole9-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole9-7.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Before you begin, secure the head. Next, clean the udder. Then sit on
          a sturdy stool and lubricate the individual teats. Pull downward from
          the base, squeeze, collect and drink.
        </p>
        <p>
          <i>Just to be clear… we’re talking about milking a cow, right?</i>
        </p>
        <p>
          Now, after exercising those forearms, grab your club with the bulbous
          head, pull it from the holder, clean the long shaft, straddle the
          ball, undulate your hips and give it a good, strong whack!
        </p>
        <p>
          <i>
            Now, wait a minute… just want to make sure I got this right…we’re
            talking about golf, right?
          </i>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 9 Taunt",
    logoItem: require(`../assets/logos/cow.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-9-1.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt-9-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Front 9 Score",
    logoItem: require(`../assets/logos/cow.jpg`),
    isFrontNine: true,
    content: (
      <p>
        Don’t cheat - tell us how many strokes you are through the first 9
        holes. FYI – Bill and Tom are shooting a splendid -1 for the front 9! 
      </p>
    ),
  },
  {
    name: "BTB Hole 10: The Holiday Hole",
    multipleLogos: [
      require(`../assets/logos/thanksgiving.jpg`),
      require(`../assets/logos/santa.jpg`),
      require(`../assets/logos/easter.jpg`),
      require(`../assets/logos/irish.jpg`),
    ],
    holeNumber: 10,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole10-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole10-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/santa1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/santa2.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          <strong>
            Here Comes Santa Claus, Here’s Comes Santa Clause, right down Santa
            Claus lane…
          </strong>
        </p>
        <p>
          <strong>
            Here comes Peter Cottontail, Hoppin’ down the bunny trail…
          </strong>
        </p>
        <p>
          Who knew that creating a hole featuring Kris Kringle would bring us
          snow? The Big Man and his friend The Easter Bunny handed out gifts of
          free drops on the green, ladies’ tee drives and Viagra-like drive
          enhancements!{" "}
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 10 Taunt",
    multipleLogos: [
      require(`../assets/logos/thanksgiving.jpg`),
      require(`../assets/logos/santa.jpg`),
      require(`../assets/logos/easter.jpg`),
      require(`../assets/logos/irish.jpg`),
    ],
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-10.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt10-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt10-3.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt10-4.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – Leprechauns / Mommy Express / Naughty Nuns",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          Maureen Barnett and Connie Allen have teamed up to create some of our
          more interesting distractions over the last few years.
        </p>
        <p>
          Whether it was the Mommy Express –{" "}
          <strong>
            “It’s time to feed all the crying widdle babies! Come here little
            ones! Mommy has a special bottle – chock-full of your favorite…
            milk, yeah, that’s right, it’s milk!”
          </strong>
        </p>
        <p>
          … Or the Lusty Leprechauns with a pot o’ gold at the end of the
          rainbow …
        </p>
        <p>
          … Or extremely naughty nuns, these girls have helped to make Beat Tom
          and Bill an extremely memorable event!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction5-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction5-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction5-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction5-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction5-5.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/lgSkK9s_sUU"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Meet the Families",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>In 19 years, we’ve given over $1.3M to over 100 families.</p>
        <p>
          Meet some of the families we’ve helped in the most recent years,
          2017-2019…{" "}
        </p>
        <p>
          2019 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2019.html">
            http://www.btbfoundation.org/who-we-helped-2019.html
          </a>
        </p>
        <p>
          2018 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2018.html">
            http://www.btbfoundation.org/who-we-helped-2018.html
          </a>
        </p>
        <p>
          2017 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2017.html">
            http://www.btbfoundation.org/who-we-helped-2017.html
          </a>
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/past1-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/past1-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/past1-3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 11: The Sponsor Hole",
    logoItem: require(`../assets/logos/elvis.jpg`),
    holeNumber: 11,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole11-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole11-8.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          <strong>“Lord Almighty, I feel my temperature rising…”</strong>
        </p>
        <p>
          Our good friends and long-time sponsors, The Rozovics Group and Red
          Caffeine, always cook up a special hole, manned by their employees
          with their own brand of wackiness! Elvis was one of our favorites!{" "}
        </p>
        <p>
          Too bad that your game is staying overnight at the Heartbreak Hotel
        </p>
        <p>
          <strong>
            <i>
              “Well since, your golf game left you, and you’re not playing so
              well, Well, just get a room, at Beat Tom & Bill’s, the Heartbreak
              Hotel”
            </i>
          </strong>
        </p>
        <p>
          <a href="https://rozcpa.com/">https://rozcpa.com/</a>
        </p>
        <p>
          <a href="https://www.redcaffeine.com/">
            https://www.redcaffeine.com/
          </a>
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 11 Taunt",
    logoItem: require(`../assets/logos/elvis.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-11.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/hole11-taunt-1.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/hole11-taunt-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Closest To the Pin Challenge",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    challengeNumber: 2,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/closest-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/closest-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/closest-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/closest-6.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          At our outing, there are many chances to challenge and carry off the
          cup, none better than the Closest to the Pin. You can see the flag and
          you can see that Tom and Bill are only a few yards from the hole. From
          this distance it looks like inches. In fact, we were surprised we
          didn’t ACE IT!
        </p>
        <p>
          Speaking of great scores and shots, in the 19 years of our event,
          we’ve had many, many BIRDIES, a few EAGLES and even TWO HOLE IN ONES!
        </p>
        <p>
          2010 – Indian Lakes Golf Course – 220 Golfers hear about Kevin Pick
          hitting his Hole-In-One to beat Bill on our Signature hole! Kevin’s
          winnings??? A stylish Beat Tom and Bill Button!
        </p>
        <p>
          2017 – White Pines Golf Course – Perennial attendee Mike Schultz
          connected and hit a GREAT SHOT, winning himself a new set of Clubs
          (not that he needed them!) While he ACED the hole, he still lost to
          Tom and Bill in the overall scoring that year. As you are now
          beginning to recognize, you better hit like a pro if you’re going to
          best these 2 superior Athletes!
        </p>
        <p>
          Pick one of the taunts. If you pick the right one, you’ll be today’s
          champion and get an extra raffle ticket for A WHEEL OF MEAT SPIN!!!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 12: The Carnival Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 12,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole12-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole12-10.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          <strong>
            <i>
              Step right up, ladies and gentlemen, boys and girls and try your
              luck at a game of chance!
            </i>
          </strong>
        </p>
        <p>
          This hole was one even PT Barnum couldn’t have envisioned! Those
          freaks of nature, Tom & Bill, are exhibiting a show of strength that
          would make even the bearded lady blush while you’re playing like
          Lobster Boy or Schlitzie the Pinhead!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 12 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-12.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt12-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – Barbershop Quartet",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          <a href="https://www.facebook.com/abileneparadoxquartet/">
            https://www.facebook.com/abileneparadoxquartet/
          </a>
        </p>
        <p>Their Facebook page touts… </p>
        <p>
          “Just a bunch of agreeable guys that found themselves singing
          together. We’re 4 human resource strategists available to counsel you
          in 4 part harmony”
        </p>
        <p>
          Now, if that doesn’t below on a golf course, I don’t know what does!!!
          Joseph Guerri and his group Abilene Paradox Quartet have been
          preaching the Barbershop Quartet gospel on the course since 2016. One
          year, they were competing for an award so they introduced us to the
          Take Note Quartet who filled in admirably.
        </p>
        <a href="https://www.facebook.com/TakeNoteQuartet/">
          https://www.facebook.com/TakeNoteQuartet/
        </a>
        <p>
          So, of course the next year – we invited ‘em all back for a Barbershop
          Quartet Battle Royale! The harmonies of “Blue Skies” keep raining down
          on the course, and now in the virtual world, my friend!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction6-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction6-8.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/k0ZftH7Sewc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mE6sE7B2hAM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/zSdY3yne_qc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Meet the Families",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>In 19 years, we’ve given over $1.3M to over 100 families.</p>
        <p>
          Meet some of the families we’ve helped in the most recent years,
          2014-2016…{" "}
        </p>
        <p>
          2016 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2016.html">
            http://www.btbfoundation.org/who-we-helped-2016.html
          </a>
        </p>
        <p>
          2015 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped---2015.html">
            http://www.btbfoundation.org/who-we-helped---2015.html
          </a>
        </p>
        <p>
          2014 Families -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2014.html">
            http://www.btbfoundation.org/who-we-helped-2014.html
          </a>
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/families-2014.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/families-2015.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/families-2016.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Hole 13 – Special BTB Introduction",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <img src={require("../assets/imgs/13-intro-1.jpg")} alt="Test" />
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/13-intro-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/13-intro-3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/BmgvuWWJJ_8"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "BTB Hole 13: The Beat Tom and Bill Hole",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: 13,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole13-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-13.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-14.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole13-15.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          This hole is where you got the chance to go mano-a-mano against Tom or
          Bill. A once in a lifetime chance to earn the coveted “I BEAT TOM &
          BILL” Button.
        </p>
        <p>
          But Tom and Bill, being the lovers of the arts that they are, also
          took this opportunity to showcase some great local talent – Bagpipers,
          Drummers, Trumpeters, Violinists, Stand-up comedians, a Calliope
          player, Dixieland Jazz Bands, and Celloists. I hope their practicing
          wasn’t distracting…
        </p>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/a0O4gdFRZEk"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "BTB Hole 13 Taunt",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-13.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt13-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt13-3.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt13-4.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt13-5.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "The Button Challenge",
    holeNumber: undefined,
    content: (
      <>
        <p>
          Since so few of you won or even competed for the Bobblehead trophy, we
          figured we needed to give you other chances to win.  So, we created
          mini challenges throughout the course.
        </p>
        <p>
          The Beat Tom and Bill hole allowed you to go up against Bill and Tom
          and it was the only place for you to earn the coveted “I BEAT TOM &
          BILL” Button.   Sadly, many of you caught us in weak moments and you
          have worn those badges of honor proudly!  Other Badges were not so
          honorable.  This challenge will allow you to pick the badge that you
          believe will earn you an extra ticket for a WHEEL OF MEAT spin. 
          Choose wisely, my friend! 
        </p>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/PpDIpRRx_G0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
    challengeNumber: 4,
    logoItem: require(`../assets/logos/main.jpg`),
  },
  {
    name: "BTB Hole 14: The Blues Hole",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: 14,
    content: (
      <>
        <p>
          Our good friend Carl Davis and members of The Carl Davis Band joined
          us on the course (Bill’s side) and in the studio for The Beat Tom and
          Bill Blues. Jay O’Rourke was on the course with members of his band
          (Tom’s side). Check these guys out here:
        </p>
        <p>
          Carl Davis Band:{" "}
          <a href="https://www.reverbnation.com/carldavisband">
            https://www.reverbnation.com/carldavisband
          </a>
        </p>
        <p>
          Jay O’Rourke:{" "}
          <a href="https://www.facebook.com/jay.orourke.3">
            https://www.facebook.com/jay.orourke.3
          </a>
        </p>
        <p>Today you are Golfing </p>
        <p>To Beat Tom and Bill</p>
        <p>All these years you’ve been losing</p>
        <p>You’re a loser still</p>
        <p>Your Drives are pathetic</p>
        <p>Can’t get off of the tee</p>
        <p>All your hooking and slicing</p>
        <p>Hitting into the trees</p>
        <p>Cause you’ve got the Blues, my friend</p>
        <p>So sit down and lace up those old golfing shoes</p>
        <p>Cause you’ll never beat them</p>
        <p>Can’t cheat or unseat them</p>
        <p>No way you’ll defeat them</p>
        <p>The You Can’t Beat Tom and Bill Blues!</p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole14-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole14-9.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 14 Taunt",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-14.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/hole14taunt-2.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "BTB Hole 15: Flight Attendants Hole",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: 15,
    content: (
      <>
        <p>
          In 2019, we were helping Laura Davis, sister of our good friend Scott
          Shields. Since Scott was part of the perennial “tied Tom & Bill a few
          times but can’t get over the hump and beat them club”, he decided to
          quit competing and organize an extremely memorable hole. Featuring
          real Flight attendants and pilots, golfers were expected to prepare
          for a rough landing, donning a life preserver and oxygen mask before
          hitting their weak shots into the water!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole15-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole15-10.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 15 Taunt",
    logoItem: require(`../assets/logos/triangle.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/taunt-15.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/taunt-15-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/taunt15-3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Distraction – Batman and Robin",
    logoItem: require(`../assets/logos/batman.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 2019 we were able to enlist the extremely talented Mike Toomey from
          WGN-TV and he agreed to get involved and play along. His Batman / Adam
          West impersonation might be the best ever and he joined us as the
          Caped Crusader. His good friend, Matt Kissane, who had helped us in
          earlier years (and is featured in a Distraction coming up) joined Mike
          as Robin, The Boy Wonder.
        </p>
        <p>
          We changed the entire course to play like Gotham City, where at each
          hole Golfers would have to Beat Tom and Bill AND overcome challenges
          from Batman’s greatest Villains! Mister Freeze played his part by
          bringing rain and howling wind but most intrepid golfers played on to
          battle the Riddler (John Beirne and Joe Dillilo), the Joker (Sarah
          Papic on violin), and other inspired games throughout the course.
        </p>
        <p>
          Not only are these guys incredibly funny, they’re great Human Beings!
          If you’d like to book either of them, check out their FaceBook pages
        </p>
        <p>
          Mike Toomey -{" "}
          <a href="https://www.facebook.com/mtoomey1">
            https://www.facebook.com/mtoomey1
          </a>
        </p>
        <p>
          Matt Kissane -{" "}
          <a href="https://www.facebook.com/mattkissanecomedy/">
            https://www.facebook.com/mattkissanecomedy/
          </a>
        </p>
        <p>
          Sarah Papic on Violin -{" "}
          <a href="https://www.facebook.com/sarah.papic">
            https://www.facebook.com/sarah.papic
          </a>
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction7-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/batman-13.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/NqixI8hM3JM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Y-VIIAhfu6A"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/QicdGCB8lJc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/RwzoBlT_Lh4"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Meet the Superheroes",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          The folks at Adtoons https://www.adtoons.com/ created the introductory
          video about our event and also created these incredible drawings,
          turning the children we were helping into their favorite Superheroes!
          Pretty Cool!!!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/super-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-6.jpeg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/super-8.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Since 2014, we’ve also been graced on the course with our own
          Superhero mascot – Brandon Hinojosa. We helped his family back then,
          and now that he’s cancer-free we’ve been able to celebrate this little
          guy every year since!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 16: The Clown Hole",
    logoItem: require(`../assets/logos/kiss.jpg`),
    holeNumber: 16,
    content: (
      <>
        <p>
          <strong>
            <i>
              The putt you missed? You’re in despair. Your game on the ground.
              Tom & Bill in Mid-Air. Send in the Clowns…
            </i>
          </strong>
        </p>
        <p>
          Remember that year, Chuck-A-Roo and his Clown Band joined us?{" "}
          <a href="https://www.facebook.com/charles.bista.9">
            https://www.facebook.com/charles.bista.9
          </a>
        </p>
        <p>
          You put the clown shoes on, fastened the bright red nose and painted
          on a smile. But that happy disposition only lasted on the tee because
          the rest of your game was meant for the circus, my friend!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole16-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole16-13.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "BTB Hole 16 Taunt",
    logoItem: require(`../assets/logos/kiss.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt-16.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt16-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt16-3.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "2 Shot Prox Challenge",
    holeNumber: undefined,
    content: (
      <>
        <p>
          As they say in Hamilton, the Musical – “I am not throwing away my
          shot” …
        </p>
        <p>…but you may as well chuck yours.</p>
        <p>Tom and Bill have hit 2 strong shots…</p>
        <p>
          (as they ALWAYS DO – these guys never miss…AND if one of them does,
          the other brother picks up the slack and ALWAYS delivers!!! “It’s the
          movement where all the hungriest brothers with something to prove
          went”)
        </p>
        <p>
          …and are mere inches away from the cup for another tap-in-BIRDIE, I’m
          sure.
        </p>
        <p>“They probably shouldn’t brag, but dang, they amaze and astonish”</p>
        <p>
          So, since you surely can’t beat these guys on the course, we’ll play a
          game of chance virtually.
        </p>
        <p>“Rise up, it’s time to take a shot!”</p>
        <p>
          Pick a picture of the golfers enjoying themselves after they’ve
          reconciled their attitude to accept another loss at the hands of Tom
          and Bill. Hey, it’s all about the mindset! You can’t win, but you can
          still have a good time!
        </p>
        <p>
          So, “laugh in the face of casualties and sorry, think past tomorrow
          AND DON’T THROW AWAY YOUR SHOT!”
        </p>
        <p>
          Pick the winning picture and win an extra Wheel of Meat Raffle ticket,
          my friend!
        </p>
      </>
    ),
    challengeNumber: 3,
    logoItem: require(`../assets/logos/main.jpg`),
  },
  {
    name: "Hole 17: The Ball Launch / Slingshot Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 17,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole17-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole17-10.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          Another hole where we let you utilize the modern implements of today’s
          technology to best us! Grab a hold of the Ball Launcher and massive
          slingshot, aim at the green, and release. Feel the pressure expel as
          your ball is catapulted hundreds of yards further than you’d ever be
          able to hit it! Sadly, you’ll need this extra yardage AND THEN SOME to
          beat our Shots!
        </p>
        <p>Tom & Bill are like John Henry, that steam driving man.</p>
      </>
    ),
  },
  {
    logoItem: require(`../assets/logos/main.jpg`),
    name: "BTB Hole 17 Taunt",
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt17-1-bogie.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "Distraction – Matt Foley, Motivational Speaker / Donald Trump",
    holeNumber: undefined,
    content: (
      <>
        <p>
          The great Matt Kissane has been joining us for the last 4 years. For
          the first 2 years as Matt Foley, next as “The Donald” and last year as
          The Boy Wonder. He’s hilarious with a HUGE heart! Check him out below
          and hire him for a Zoom call or a Holiday Party. He definitely brings
          the laughs!
        </p>
        <p>
          <a href="https://www.facebook.com/mattkissanecomedy/">
            https://www.facebook.com/mattkissanecomedy/
          </a>
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/distraction7-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/distraction7-11.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/FNCpDCAgZLc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/U3mWsD1Ij78"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
    logoItem: require(`../assets/logos/batman.jpg`),
  },
  {
    name: "Meet the Families - The Early Years",
    logoItem: require(`../assets/logos/chef.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          In 19 years, we’ve given over $1.3M to over 100 families. Meet some of
          the families and organizations we’ve helped in the early years,
          2002-2013
        </p>
        <p>
          2013 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2013.html">
            http://www.btbfoundation.org/who-we-helped-2013.html
          </a>
        </p>
        <p>
          2012 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-2012.html">
            http://www.btbfoundation.org/who-we-helped-2012.html
          </a>
        </p>
        <p>
          2011 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-in-2011.html">
            http://www.btbfoundation.org/who-we-helped-2011.html
          </a>
        </p>
        <p>
          2010 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-in-2010.html">
            http://www.btbfoundation.org/who-we-helped-2010.html
          </a>
        </p>
        <p>
          2009 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-in-2009.html">
            http://www.btbfoundation.org/who-we-helped-in-2009.html
          </a>
        </p>
        <p>
          2008 -{" "}
          <a href="http://www.btbfoundation.org/who-we-helped-in-2008.html">
            http://www.btbfoundation.org/who-we-helped-in-2008.html
          </a>
        </p>
        <p>
          Cancer Care Foundation – Began helping them in 2003 and gave them $85K
          before they disbanded.{" "}
        </p>
        <p>St. Patrick’s High School Endowment - $20K</p>
        <p>
          American Cancer Society and Avon Walk for Breast Cancer – Our first 2
          years we gave $7,500 to these worthy charities.{" "}
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/past3-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/past3-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/past3-3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name:
      "Sally's Studio - art therapy for Cancer Patients at Advocate / Lutheran General Hospital",
    logoItem: require(`../assets/logos/sally.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <a href="http://www.btbfoundation.org/sallys-studio-page.html">
          http://www.btbfoundation.org/sallys-studio-page.html
        </a>
        <p>
          Sally’s Studio is a non-for profit Art Therapy Program, funded by the
          Beat Tom & Bill Charity Golf Outing
        </p>
        <p>
          In 2009, we sponsored Sally Gorney-Avila and her family. Sadly, Sally
          passed away before our outing. The next year, her friends wanted to
          create a separate charity to directly help cancer patients on an
          ongoing basis and honor her memory.  
        </p>
        <p>
          Their journey begins with Advocate / Lutheran General Hospital and
          their Advanced Center for Care.  This program combines Sally's love of
          art with the strength and tenacity she exhibited while fighting this
          disease.  Their goal is to help make a patient's day a bit brighter as
          they receive their cancer treatment.  The hope of Sally's Studio is
          that this program will open the door to the art world, provide
          meditative relaxation, or simply put a smile on each patient's face. 
          It is our way of channeling our love and energy for Sally into a
          positive force by creating a program to honor her legacy.
        </p>
        <p>
          Sally's Studio is made possible by The Schneider Family Fund, our BTB
          Foundation, and other private donations in conjunction with the
          Brickton Art Center located at Busse Highway, Park Ridge, IL.   
          Sally's Studio is completely free to all patients receiving cancer
          treatments at Lutheran General Hospital and is intended to promote
          healing, balance and good vibes.
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 18: The Toilet Hole",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: 18,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/hole18-1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-6.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-7.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-8.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-9.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-10.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-11.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-12.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole18-13.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <p>
          We saved the best for last! Consistently voted everyone’s favorite
          hole!
        </p>
        <p>
          Nothing like sitting on the throne and trying to expel the demons of
          the past 18 contests. You’ve probably felt a little blocked up, with a
          ton of pressure on your bowels and sphincter this whole round. It’s
          not easy battling Tom and Bill, and something has to give.
        </p>
        <p>
          So… sit down, my friend. Take a load off…and drop a deuce, why don’t
          you?
        </p>
        <p>
          It all DEPENDS on your outlook. One man’s pretty is another man’s
          shitty!!!
        </p>
      </>
    ),
  },
  {
    name: "BTB Hole 18 Taunt",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <UncontrolledCarousel
            items={[
              {
                src: require("../assets/imgs/taunt18-1.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt18-2.jpg"),
                altText: "",
                caption: "",
              },
              {
                src: require("../assets/imgs/taunt18-3.jpg"),
                altText: "",
                caption: "",
              },
            ]}
          />
        </Col>
      </Row>
    ),
  },
  {
    name: "The Toilet Hole Challenge",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    challengeNumber: 5,
    content: (
      <>
        <p>Last challenge of the day, my friend!</p>
        <p>Grip it and Grunt it!</p>
        <p>Rip it and Rip one!</p>
        <p>Swing your Shaft and Sling it far and wide!</p>
        <p>
          After getting the right button, pick the right taunt and you’ll earn
          an extra Wheel of Meat Raffle Ticket!
        </p>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/toilet1.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/toilet2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/toilet3.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Back 9 Score",
    logoItem: require(`../assets/logos/cow.jpg`),
    isBackNine: true,
    content: <p>Don't cheat - tell us your total score for the back 9!</p>,
  },
  {
    name: "Course Par",
    logoItem: require(`../assets/logos/cow.jpg`),
    isCoursePar: true,
    content: <p>Don't cheat - tell us the par for your course!</p>,
  },
  {
    name: "BTB - Your Performance",
    logoItem: require(`../assets/logos/cow.jpg`),
    isSummary: true,
    content: <></>,
  },
  {
    name: "The 19th Hole – Spin THE WHEEL OF MEAT",
    logoItem: require(`../assets/logos/meat.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/meat-2.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/meat-3.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/meat-4.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/meat-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole19-5.jpg"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/hole19-6.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/DOPDhcHHIVk"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/XxP4xay1jvg"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Thanks to Our Sponsors",
    logoItem: require(`../assets/logos/transeo.png`),
    holeNumber: undefined,
    content: (
      <>
        <p>
          The BTB Virtual Event is sponsored by Transeo, which builds life
          readiness software for schools. If your school is looking for a way to
          track your college and career readiness initiatives, check out{" "}
          <a href="https://gotranseo.com">https://gotranseo.com</a>
        </p>
      </>
    ),
  },
  {
    name: "Thanks to our Main Event Sponsors",
    logoItem: require(`../assets/logos/main.jpg`),
    holeNumber: undefined,
    content: (
      <>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <UncontrolledCarousel
              items={[
                {
                  src: require("../assets/imgs/sponsor1.png"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/sponsor2.png"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/sponsor3.png"),
                  altText: "",
                  caption: "",
                },
                {
                  src: require("../assets/imgs/sponsor4.jpg"),
                  altText: "",
                  caption: "",
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    name: "Bill and Tom Opera",
    holeNumber: undefined,
    content: (
      <>
        <p>
          Our good friend, Christian Ketter, a real, honest to goodness Tenor
          Opera singer, lends his incredible talents to our own Opera which has
          begun the evening festivities of our outing since 2011!
        </p>
        <p>
          It’s one of our favorite traditions of the event! Check him out at{" "}
          <a href="www.christianketter.com/Homepage.html">
            www.christianketter.com/Homepage.html
          </a>
        </p>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/WuGyMEYziXM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          className="mt-3"
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vcOo0XTmnaU"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "Tom's Books",
    holeNumber: undefined,
    content: (
      <>
        <p>
          Here’s another cool way to help a family…  Tom has published 2
          children’s books – 100% of the proceeds go to the BTB Foundation.
          Purchase here:{" "}
          <a href="https://beat-tom-bill-2020-charity-golf-outing.perfectgolfevent.com/registration">
             https://beat-tom-bill-2020-charity-golf-outing.perfectgolfevent.com/registration
          </a>
        </p>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/VJT2Ecww900"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
  {
    name: "You’ve survived & Thank you from Beat Tom and Bill",
    holeNumber: undefined,
    content: (
      <>
        <iframe
          title="Intro"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Z8zIVLKp7yo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    ),
  },
];
