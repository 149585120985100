export type BasicUser = {
  id: number;
  name: string;
  email: string;
};

const UserManager = {
  saveToken(token: string) {
    localStorage.setItem("btb_token", token);
  },
  saveProfile(user: BasicUser) {
    localStorage.setItem("btb_user", JSON.stringify(user));
  },
  getToken() {
    return localStorage.getItem("btb_token");
  },
  get(): BasicUser | undefined {
    const user = localStorage.getItem("btb_user");
    if (user) {
      return JSON.parse(user);
    }
  },
  clear() {
    localStorage.clear();
  },
  isLoggedIn() {
    if (this.getToken()) {
      return this.getToken() != null;
    }

    return false;
  },
};

export default UserManager;
