/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <>
          <footer className="footer">
            <Container>
              <Row className="row-grid align-items-center my-md">
                <Col lg="6">
                  <h3 className="text-primary font-weight-light mb-2">
                    Thank you for supporting the event!
                  </h3>
                  <h4 className="mb-0 font-weight-light">
                    Please reach out if you have any questions.
                  </h4>
                </Col>
              </Row>
              <hr />
              <Row className="align-items-center justify-content-md-between">
                <Col md="6">
                  <div className="copyright">
                    © {new Date().getFullYear()}{" "}
                    <a href="http://www.btbfoundation.org/" target="_blank">
                      BTB Foundation
                    </a>
                    .
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </>
      </>
    );
  }
}

export default SimpleFooter;
