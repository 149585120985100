import {
  handlePostRequest,
  handleGetRequest,
  handlePatchRequest,
} from "./base";
import { BasicUser } from "./UserManager";

export type Results = {
  results: {
    id: number;
    frontNine?: number;
    backNine?: number;
    coursePar?: number;
    itemNumber: number;
    firstChallengeResults: number;
    secondChallengeResults: number;
    thirdChallengeResults: number;
    fourthChallengeResults: number;
    fifthChallengeResults: number;
  };
};

const Users = {
  async exchangeLoginToken(token: string) {
    const data: string = await handlePostRequest(`/login/${token}`);
    return data;
  },
  async register(name: string, email: string, username: string) {
    return await handlePostRequest(`/register`, { name, email, username });
  },
  async getProfile() {
    const data: BasicUser = await handleGetRequest(`/me`);
    return data;
  },
  async getCourse(course: number) {
    const results: Results = await handleGetRequest(`/results/${course}`);
    return results;
  },
  async updateCourse(course: number, hole: number, value: number) {
    return handlePatchRequest(`/results/${course}/${hole}/${value}`);
  },
  async updateCourseItemNumber(course: number, type: "increase" | "decrease") {
    return handlePatchRequest(`/results-item/${course}/${type}`);
  },
  async updateChallenge(course: number, challenge: number, result: number) {
    return handlePatchRequest(`/challenge/${course}/${challenge}/${result}`);
  },
  async skip(
    course: number,
    frontNine: number,
    backNine: number,
    coursePar: number
  ) {
    return handlePostRequest(`/skip/${course}`, {
      frontNine,
      backNine,
      coursePar,
    });
  },
  async addFrontNine(course: number, value: number) {
    return handlePostRequest(`/front-nine/${course}/${value}`);
  },
  async addBackNine(course: number, value: number) {
    return handlePostRequest(`/back-nine/${course}/${value}`);
  },
  async addCoursePar(course: number, value: number) {
    return handlePostRequest(`/course-par/${course}/${value}`);
  },
};

export default Users;
